








import { Component, Prop, Vue } from "vue-property-decorator";
import QRCode from "qrcode";

@Component
export default class gQrCode extends Vue {
  @Prop() private url!: string;

  mounted(): void {
    var canvas = document.getElementById("canvas");

    QRCode.toCanvas(canvas, this.url, { width: 200 }, function (error: any) {
      if (error) console.error(error);
    });
  }
}
